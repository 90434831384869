<template>
  <div class="sliding-clock">
    <div class="hour-container">
      <div class="hour-tens-container item-container" :style="getTranslateY(currentHourTens)">
        <div
          v-for="tens in hourTens"
          :key="'hourTens' + tens"
          class="hour-tens-item time-number-item"
          :class="{ 'is-current': tens === currentHourTens }"
        >{{ tens }}</div>
      </div>
      <div class="hour-units-container item-container" :style="getTranslateY(currentHourUnits)">
        <div
          v-for="units in hourUnits"
          :key="'hourUnits' + units"
          class="hour-units-item time-number-item"
          :class="{ 'is-current': units === currentHourUnits }"
        >{{ units }}</div>
      </div>
    </div>
    <div class="symbol-container">:</div>
    <div class="minute-container">
      <div class="minute-tens-container item-container" :style="getTranslateY(currentMinuteTens)">
        <div
          v-for="tens in minuteTens"
          :key="'minuteTens' + tens"
          class="minute-tens-item time-number-item"
          :class="{ 'is-current': tens === currentMinuteTens }"
        >{{ tens }}</div>
      </div>
      <div class="minute-units-container item-container" :style="getTranslateY(currentMinuteUnits)">
        <div
          v-for="units in minuteUnits"
          :key="'minuteUnits' + units"
          class="minute-units-item time-number-item"
          :class="{ 'is-current': units === currentMinuteUnits }"
        >{{ units }}</div>
      </div>
    </div>
    <div class="symbol-container">:</div>
    <div class="second-container">
      <div class="second-tens-container item-container" :style="getTranslateY(currentsecondTens)">
        <div
          v-for="tens in secondTens"
          :key="'secondTens' + tens"
          class="second-tens-item time-number-item"
          :class="{ 'is-current': tens === currentsecondTens }"
        >{{ tens }}</div>
      </div>
      <div class="second-units-container item-container" :style="getTranslateY(currentsecondUnits)">
        <div
          v-for="units in secondUnits"
          :key="'secondUnits' + units"
          class="second-units-item time-number-item"
          :class="{ 'is-current': units === currentsecondUnits }"
        >{{ units }}</div>
      </div>
    </div>
    <el-card class="tips">
      该时钟创意来自于
      <el-link type="primary" href="https://store.steampowered.com/app/431960/Wallpaper_Engine/" target="_black">Wallpaper Engine</el-link>
      软件steam创意工坊
      <el-link type="primary" href="https://steamcommunity.com/profiles/76561198082633524/" target="_black">DarkBlue</el-link>用户
    </el-card>
  </div>
</template>
<script>
export default {
  name: 'SlidingClock',
  data() {
    return {
      hourTens: [0, 1, 2],
      hourUnits: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      minuteTens: [0, 1, 2, 3, 4, 5],
      minuteUnits: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      secondTens: [0, 1, 2, 3, 4, 5],
      secondUnits: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      currentHour: 0,
      currentMinute: 0,
      currentSecond: 0,
      intervalNum: ''
    }
  },
  computed: {
    currentHourTens() {
      return Math.floor(this.currentHour / 10)
    },
    currentHourUnits() {
      return this.currentHour % 10
    },
    currentMinuteTens() {
      return Math.floor(this.currentMinute / 10)
    },
    currentMinuteUnits() {
      return this.currentMinute % 10
    },
    currentsecondTens() {
      return Math.floor(this.currentSecond / 10)
    },
    currentsecondUnits() {
      return this.currentSecond % 10
    }
  },
  created() {
    this.setHMS()
    this.intervalNum = setInterval(this.setHMS, 1000)
  },
  destroyed() {
    if (this.intervalNum) {
      clearInterval(this.intervalNum)
    }
  },
  methods: {
    getTranslateY(num) {
      return { transform: 'translateY(' + (-60 * num + 270) + 'px)' }
    },
    setHMS() {
      const time = new Date()
      this.currentHour = time.getHours()
      this.currentMinute = time.getMinutes()
      this.currentSecond = time.getSeconds()
    }
  }
}
</script>
<style lang="scss" scoped>
.sliding-clock {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 600px;
    height: calc(100vh - 193px);
}
.hour-container,
.minute-container,
.second-container {
    display: flex;
    align-items: flex-start;
    height: 600px;
}
.symbol-container {
    width: 40px;
    height: 60px;
    border-radius: 8px;
    background-color: #dfe6f6;
    box-shadow: -10px -10px 20px -5px #f9fbfd, 5px 5px 10px #a9bee8;
    margin: 0 20px;
    font-size: 30px;
    font-weight: bold;
    color: #3e6ccb;
    line-height: 60px;
    text-align: center;
}
.item-container {
    transition: transform 500ms ease-in-out;
    border-radius: 8px;
    background-color: #dfe6f6;
    box-shadow: -10px -10px 20px -5px #f9fbfd, 10px 10px 20px #a9bee8;
    margin: 0 20px;
    .time-number-item {
        padding: 10px;
        width: 60px;
        height: 60px;
        display: grid;
        place-items: center;
        color: #f9fbfd;
        transition: all 500ms 100ms ease;
        border-radius: 50%;
        &.is-current{
            color: #3e6ccb;
            font-weight: bold;
            transform: scale(1.2);
            background-color: #dfe6f6;
            box-shadow: -10px -10px 20px -5px #f9fbfd, 10px 10px 20px #a9bee8;
        }
    }
}
.tips {
    position: absolute;
    width: 250px;
    right: 30px;
    top: 30px;
    font-size: 14px;
}
</style>
